import {
  Component,
  inject,
  OnInit,
} from '@angular/core';
import { FieldTypeConfig } from '@ngx-formly/core';
import { FieldType } from '@ngx-formly/material';
import { LookupService } from '../../services/lookup.service';
import { Store } from '@ngrx/store';
import { map } from 'rxjs';
import { ConfirmDialogComponent, ConfirmDialogSearchComponent } from '@autobot/shared';
import { untilDestroyed } from '@ngneat/until-destroy';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'employee-search-formly',
  template: `
  <employee-search [accountId]="(accountId$ | async)"  style="width: 100%;" (employeeSearchValue)="receiveSearchValue($event)"
  (clearSearchValue)="clearValue()"></employee-search>
  `,
  styles: [
    `
      .mat-input-element {
        width: 90%;
      }
      .error {
        font-size: 75%;
        position: absolute;
        margin-top: 8px;
      }
    `,
  ],
})
export class EmployeeSearchFormlyComponent
  extends FieldType<FieldTypeConfig>
  implements OnInit {
  constructor(private store: Store, private dialog: MatDialog,) {
    super();
  }

  svc = inject(LookupService);
  accountId$: any;

  receiveSearchValue(event) {
    const specialCharRegex = /[^a-zA-Z0-9.,\&_\- \#\s]/g;
   
    let ctrlSupervisor = this.form.controls['intake']?.controls['supervisor']?.controls['demographics']
    if (ctrlSupervisor) {
      ctrlSupervisor.controls['firstName'].setValue(event.supervisorFirstName);
      ctrlSupervisor.controls['lastName'].setValue(event.supervisorLastName);
      ctrlSupervisor.controls['email'].setValue(event.supervisorEmail);

    }
    let ctrlEmployee = this.form.controls['intake']?.controls['employee']?.controls['demographics']
    if (ctrlEmployee) {
      ctrlEmployee.controls['firstName'].setValue(event.firstName);
      ctrlEmployee.controls['lastName'].setValue(event.lastName);
      ctrlEmployee.controls['middleNameInitial'].setValue(event.middleInitial);
      ctrlEmployee.controls['jobTitle'].setValue(event.jobTitle);
      ctrlEmployee.controls['email'].setValue(event.employeeEmail);
      ctrlEmployee.controls['employeeId'].setValue(event.clientEmployeeId);
      ctrlEmployee.controls['ssnValue'].setValue(event.ssn);
      ctrlEmployee.controls['ssn'].setValue('FullSSN');
      ctrlEmployee.controls['gender'].setValue(event.gender);
      ctrlEmployee.controls['maritalStatus'].setValue(event.maritalStatus);
      ctrlEmployee.controls['dob'].setValue(event.dateOfBirth);

    }
    let ctrlEmployeePhone = this.form.controls['intake']?.controls['employee']?.controls['demographics']?.controls['phone']
    if (ctrlEmployeePhone) {
      ctrlEmployeePhone.controls['home'].setValue(event.homePhone);
      ctrlEmployeePhone.controls['work'].setValue(event.workPhone);

    }
    
    let ctrlHomeAddress = this.form.controls['intake']?.controls['employee']?.controls['address']?.controls['home'];
    if (ctrlHomeAddress) {
     
      ctrlHomeAddress.controls['address1'].setValue(event.address1?.replace(specialCharRegex,''));
      ctrlHomeAddress.controls['address2'].setValue(event.address2?.replace(specialCharRegex,''));
      ctrlHomeAddress.controls['city'].setValue(event.city);
      ctrlHomeAddress.controls['state'].setValue(event.state);
      ctrlHomeAddress.controls['zip'].setValue(event.zipCode);
    }

    let stateLabel = '';
    this.svc.getItemByfieldID('states', event.location.state).subscribe((data) => {
      if (data?.length > 0) {
        stateLabel = data[0].label;
      }
    });

    let ctrl = this.form.controls['intake']?.controls['location']?.controls['work'];
    if (ctrl) {
      const specialCharLocationcodeRegex = /[^a-zA-Z0-9\s]/g;
      ctrl.controls['zip'].setValue(event.location.zip);
      ctrl.controls['city'].setValue(event.location.city);
      ctrl.controls['state']?.setValue(stateLabel);
      ctrl.controls['code'].setValue(event.location?.code?.replace(specialCharLocationcodeRegex, ''));
      ctrl.controls['name'].setValue(event.location.name);
      ctrl.controls['localPhone'].setValue(event.location.phoneNumber);
      ctrl.controls['address1'].setValue(event.location?.address?.replace(specialCharRegex,''));
      ctrl.controls['address2'].setValue(event.location?.address2?.replace(specialCharRegex,''));
      ctrl.controls['division']?.setValue(event.location.division);
    }
  }

  clearField() {

    let ctrlManager = this.form.controls['intake']?.controls['supervisor']?.controls['demographics']
    if (ctrlManager) {
      ctrlManager.controls['firstName'].setValue('');
      ctrlManager.controls['lastName'].setValue('');
      ctrlManager.controls['contactPhone'].setValue('');
      ctrlManager.controls['email'].setValue('');
      ctrlManager.controls['jobTitle'].setValue('');
    }
    let ctrlEmployee = this.form.controls['intake']?.controls['employee']?.controls['demographics']
    if (ctrlEmployee) {
      ctrlEmployee.controls['firstName'].setValue('');
      ctrlEmployee.controls['lastName'].setValue('');
      ctrlEmployee.controls['middleNameInitial'].setValue('');
      ctrlEmployee.controls['jobTitle'].setValue('');
      ctrlEmployee.controls['email'].setValue('');

      ctrlEmployee.controls['employeeId'].setValue('');
      ctrlEmployee.controls['ssnValue'].setValue('');
      ctrlEmployee.controls['ssn'].setValue('');
      ctrlEmployee.controls['gender'].setValue('');
      ctrlEmployee.controls['maritalStatus'].setValue('');
      ctrlEmployee.controls['dob'].setValue('');
    }

    let ctrlHomeAddress = this.form.controls['intake']?.controls['employee']?.controls['address']?.controls['home'];
    if (ctrlHomeAddress) {
      ctrlHomeAddress.controls['address1'].setValue('');
      ctrlHomeAddress.controls['address2'].setValue('');
      ctrlHomeAddress.controls['city'].setValue('');
      ctrlHomeAddress.controls['state'].setValue('');
      ctrlHomeAddress.controls['zip'].setValue('');
    }

    let ctrlEmployeePhone = this.form.controls['intake']?.controls['employee']?.controls['demographics']?.controls['phone']
    if (ctrlEmployeePhone) {
      ctrlEmployeePhone.controls['home'].setValue('');
      ctrlEmployeePhone.controls['work'].setValue('');
    }

    let ctrl = this.form.controls['intake']?.controls['location']?.controls['work'];
    if (ctrl) {
      ctrl.controls['zip'].setValue('');
      ctrl.controls['city'].setValue('');
      ctrl.controls['state']?.setValue('');
      ctrl.controls['code'].setValue('');
      ctrl.controls['name'].setValue('');
      ctrl.controls['localPhone'].setValue('');
      ctrl.controls['address1'].setValue('');
      ctrl.controls['address2'].setValue('');
      ctrl.controls['division']?.setValue('');
    }
  }

  clearValue() {
    const data = {
      title: 'Warning',
      message:
        'This action will overwrite all the information in the following sections: Supervisor Demographics, Employee Demographics, Employee’s Home Address and Work Location.<br><br>This action cannot be undone, and you will lose all edited information. <br/><br/>Are you sure you want to continue?',
      acceptButtonText: 'Ok',
      cancelButtonText: 'Cancel',
    };

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '700px',
      data,
    });
    
    dialogRef
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.clearField();
        }
      });
    // this.clearfield();
  }

  ngOnInit() {
    this.accountId$ = this.store.pipe(
      map((data: any) => data?.session?.case?.accountId)
    );
  }
}
