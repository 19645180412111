import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as _ from 'lodash';
import { getValueFromPath } from '../helpers';

export const selectSessionState = createFeatureSelector<any>('session');

export const selectCaseState = createSelector(
    selectSessionState,
    (state) => state.case
);

export const selectCaseFieldValue = (fieldPath: string) => createSelector(
    selectCaseState, (state) => {
    let caseModel = state.model ?? {};
    return {
        fieldText: getValueFromPath(caseModel, fieldPath),
    };
});

